@import 'reset.css';

:root {
  --color-sea: #004768;
  --color-orange: #ff8800;
  --color-black:#222222;
  --color-super-black:#000000;
  --color-red:#ff0034;  
  --color-light-grey:#efefef;
  --color-light-blue:#00a5d8;
  --color-mid-grey:#cfcfcf;
  --color-brand-primary:#008cce;  
  --color-vintage-sea:#adf;
  --color-vintage-border:#222;
  --color-vintage-land:#ffc;
  --color-dark-yellow:#f0e62f;
  
  --color-map-sea:#1f2d32;
  --color-white:#ffffff;
  --color-map-highlight: var(--color-aircraft-active);
  --color-map-land:#dddddd;
  --color-map-ice:#e9f3f5;
  --color-map-graticule:#fff;
  
  --color-text-label: #222;
  --color-text-label-background:#fff;
  --element-height: 50vh;
  --color-aircraft-north:#d6ca00;
  --color-aircraft-active:#00a5d8;  
  --color-aircraft-north:#ffac00;

  --color-light-blue:#a8e1f3;
  --color-aircraft:rgba(255, 71, 123, 0.932);
  --font-sans: "Karla", helvetica, sans-serif;
  --font-serif: "Forum",serif;

  --header-margin: 7rem;

  --font-size-xxxx-small: 45%; 
  --font-size-xxx-small: 55%;
  --font-size-xx-small: 65%;
  --font-size-x-small: 75%;
  --font-size-small: 77%;
  --font-size-medium: 100%;
  --font-size-medium-bigger: 105%;
  --font-size-large: 125%;
  --font-size-x-large: 150%;
  --font-size-xx-large: 200%;
  --font-size-xxx-large: 260%;
}

@media only screen and (min-width: 45rem) {
	:root {
		--element-height: 75vh;
		--font-size-x-small: 85%;
		--font-size-small: 87%;
		--font-size-medium: 100%;
		--font-size-medium-bigger: 112%;
		--font-size-x-large: 200%;
	}	
}

body {
	font-family: var(--font-sans);
	background-color:var(--color-white);
	margin: 0;
	padding: 0;
	line-height: 1.25;
	font-weight: 400;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

#root {
  overflow: hidden;
}

svg.map {
	transition: background 0.25s ease-in-out;
	/* background:var(--color-sea); */
}	

svg.map {
	position: fixed;
	top:0;
	z-index:10;
	width:100%;
	height:100%;
	bottom:0;
	right:0;
	left:0;
}

body.vintage,
body.north {
	--color-map-sea:#002332;
	--color-white:#ffffff;
	--color-map-highlight: var(--color-aircraft-active);
	--color-map-land:#0f0d0d;
	--color-map-ice:#1D3A46;
	--color-map-graticule:#fff;
	--color-map-russia:#0f0d0d;
	--color-map-greenland: #0f0d0d;
	--color-map-border:#fff;
}

body.vintage svg.map,
body.north svg.map {
	background-color:var(--color-map-sea);
}

body.south svg.map {
	background-color:var(--color-map-sea);
}

.aircraft-text {
  fill: var(--color-black); /* or any other color you prefer */
  font-size: 12px; /* adjust as needed */
  pointer-events: none; /* ensures the text doesn't interfere with click events */
}

/* .attr("fill", "var(--color-aircraft)") */

.aircraft-marker:hover {
	cursor: pointer;
}

	path.aircraft-marker {
		fill:var(--color-brand-primary);
	}

	path.aircraft-marker:hover {
		fill:var(--color-dark-yellow);
	}
	
	path.aircraft-marker.selected-aircraft {
		fill:var(--color-dark-yellow);
	}
	
@keyframes aircraft-div-enter {
	from { 		
		transform:translateY(0.75rem);		
		opacity: 0; 
	}
	to { 
		opacity: 1;
		transform:translateY(0rem); 
	}
}	

@keyframes aircraft-div-leave {
	from { 
		opacity: 1;
		transform:translateY(0rem); 
		
	}
	to { 
		transform:translateY(0.75rem);
		opacity: 0; 
	}
}	

.data-display {
	padding:1rem;
	width:100%;
	z-index:100	;
	position: fixed;
	bottom:0%;
	display:flex;
	justify-content: center;
	background:white;
}

	.data-display b {
		font-weight: normal;
	}

.aircraft-details-enter {
	animation: aircraft-div-enter 0.25s ease-out forwards;
}

.aircraft-details-leave {
	animation: aircraft-div-leave 0.25s ease-out forwards;
}
	
.aircraft-details {	
	border-top-right-radius: 0.25rem;
	border-top-left-radius: 0.25rem;
	position: fixed;
	bottom:0rem;
	z-index:11;
	width: 100%;
	max-height: auto;
	transition: height 0.25s ease-in-out;
	left:0rem;
	color:var(--color-black);
	background-color:var(--color-white);
	overflow: hidden;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.15);
}	

	.aircraft-details.north-pole .airline-header {
		background-color:var(--color-aircraft-north);
	}

	@media only screen and (min-width: 45rem) {
		.aircraft-details {
			left:calc(50% - 12rem);
			width:26rem;
			bottom:0rem;	
		}
	}

	.aircraft-details .details-wrapper {
		position: relative;
		z-index:2;
	}
	
	.aircraft-details .airline {
		font-weight: bold;
		font-size:var(--font-size-medium);
	}
	
	.aircraft-details .aircraft {
		font-size:var(--font-size-medium);
	}
	
	.airline-header {
		display:flex;
		padding:0.25rem 0.5rem;
		background-color:var(--color-light-blue);
		align-items: center;
		align-content: center;
		justify-content: space-between;
	}
	
	.airline-header button {
		position: absolute;
		top:-0.1rem;
		display:none;
		margin:0;
		padding:0;
		background:none;
		border:none;
		font-size:var(--font-size-x-large);
		color:var(--color-white);
		left:0.5rem;
	}
	
	.airline-header button:hover {
		cursor: pointer;	
	}
	
	.context-frame {
		position: relative;
		z-index:12;
	}
	
	.aircraft-details .contextual-info {
		padding:0.5rem;
		display:flex;
		justify-content: space-between;
	}
	
	.aircraft-details .contextual-info h4 {
		margin:0;
	}
		
	.aircraft-details .contextual-info .connected {
		margin:0 1rem;
		align-self: center;
		flex:1 1 50%;
		content:'';
		height:0.05rem;
		position: relative;
		background:var(--color-black);	
	}	
	
	.aircraft-details .contextual-info .connected:before {
		width:0.4rem;
		height:0.4rem;
		display:block;
		content:'';
		position: absolute;
		top:-0.17rem;
		border:1px solid var(--color-black);
		border-radius: 1rem;
		left:-0.30rem;
	}	
	
	.aircraft-details .contextual-info .connected:after {
		width:0.4rem;
		height:0.4rem;
		background-color:var(--color-black);
		display:block;
		content:'';
		position: absolute;
		top:-0.17rem;
		border-radius: 1rem;
		right:0;		
	}
	
	.aircraft-details .contextual-info .connected b {
		font-weight: normal;
		font-size:var(--font-size-xx-small);
		line-height: 1;
		position: absolute;
		text-transform: uppercase;
		text-align: center;
		width:100%;
	}
	
	.aircraft-details .contextual-info .connected b:first-of-type {
		top:-1rem;
	}
	
	.aircraft-details .contextual-info .connected b:last-of-type {
		bottom:-1rem;
	}
	
	.aircraft-details .tech {
		display:flex;
		background-color:var(--color-light-grey);
		border-top:1px solid var(--color-white);
		padding:0.15rem;
		justify-content: space-evenly;
	}
	
	.aircraft-details .tech span {
		padding:0 0.4rem;	
		font-size:var(--font-size-small);
	}
	
	.aircraft-image {
		position: absolute;
		top:0;
		z-index:10;
		width:100%;
		height:100%;
		bottom:0;	
		background-color:var(--color-brand-primary);
	}
	
	.aircraft-image img {
		object-fit: cover;
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		right:0;
		z-index:10;
		bottom:0;
	}

.fade-enter {
	  opacity: 0;
	}
	
	.fade-enter-active {
	  opacity: 1;
	  transition: opacity 300ms;
	}
	
	.fade-exit {
	  opacity: 1;
	}
	
	.fade-exit-active {
	  opacity: 0;
	  transition: opacity 300ms;
	}
	
.north-pole-certificate {
	padding:0.5rem 1rem;
	background-repeat: repeat;
	text-align: center;
	margin:0.5rem;
	position: relative;
	border-radius: 1.5rem;
	border: 4px dotted var(--color-aircraft-north);
	background: repeating-linear-gradient(
	  -45deg,
	  #fff,
	  #fff 10px,
	  #fff7e5 10px,
	  #fff7e5 20px
	);
}	
	  
	  .north-pole-certificate .certificate {
	  }

	.north-pole-certificate h4 {
		display:inline-block;
		width:100%;
		position: relative;
		margin-top:0.5rem;
		margin-bottom:1rem;
		text-transform: uppercase;		
	}
	
		.notice {
			margin-top:0.5rem;
			border-top:1px solid var( --color-aircraft-north);
			padding:0.5rem;
			font-size:var(--font-size-x-small);
		}
	
		.coord {
			text-transform: uppercase;
			position: absolute;
			font-size:var(--font-size-x-small);
		}
		
		.coord:nth-of-type(1) {
			position: absolute;
			top:1rem;
			left:1rem;
		}
		
		.coord:nth-of-type(2) {			
			top:1rem;
			right:1rem;
		}
	
		.north-pole-certificate h4:after,
		.north-pole-certificate h4:before {
			position: absolute;
			display:block;
			content:'';
			width:40%;
			height:0.1rem;
			left:30%;
			background-color:var(--color-black);
		}
		
		.seal {
			max-width:100px;
			margin:0.5rem auto 0.5rem auto;
			width:100%;
		}
		
		.seal svg {
			max-width:100%;
			height: auto;
		}
	
		.north-pole-certificate h4:after {			
			top:-7px;			
		}
		
		.north-pole-certificate h4:before {
			bottom:-7px;		
		}

	.north-pole-certificate small {
		padding-top:2rem;
	}
	
	.north-pole-certificate small strong {
		border-bottom:1px solid var(--color-black);
	}
	
	.north-pole-certificate .mini-map {
		border:1px solid var(--color-black);
		padding-bottom:30%;	
		background-color:var(--color-white);
	}

	.north-pole-certificate h1 {
		letter-spacing: 0.05rem;
		font-family: var(--font-serif);
		font-weight: normal;
		font-size:var(--font-size-xx-large);
		text-align: center;
		margin-bottom:1rem;
		text-transform: uppercase;
	}

	.north-pole-certificate small {
		line-height: 1.1;
	}

@keyframes certificate-btn-ani {
	  0 {
		  background-color:var( --color-aircraft-north);
	  }
	  50% {
		  background-color:var(--color-white);
	  }
	  100% {
		  background-color:var( --color-aircraft-north);
	  }
	}


.certificate-btn {
	animation: certificate-btn-ani 5s infinite;
	display:flex;
	background-color:var( --color-aircraft-north);
	border:none;
	color:var(--color-black);
	padding:0.5rem;
	margin:0 auto 0.5rem auto;
	border-radius: 0.5rem;
	justify-content: center;
	transition: all 0.25s ease-in-out;
}

	.certificate-btn.active {
		background-color:var(--color-orange);
		animation: none;
	}

	.certificate-btn:hover {
		cursor: pointer;
	}
		
	.certificate-btn small {
		font-weight: bold;	
		line-height: 1.1;
	}

nav {
	position: fixed;
	bottom:0rem;
	display:flex;	
	justify-content: center;
	z-index:11;
	width:100%;
}

	@media only screen and (min-width: 45rem) {
		nav {
			background-color:transparent;
		}
	}

	nav ul {
		border-top-left-radius: 0.5rem;
		border-top-right-radius: 0.5rem;
		list-style: none;
		margin:0;
		padding:0;
		display:grid;
		grid-template-columns: auto auto auto auto;
		overflow: hidden;
	}
		
	nav ul li {
		display:flex;
		flex-direction: column;
		justify-content: flex-end;
		margin:0;
		padding:0;
	}
	
	nav ul li b {
		padding-bottom:0.35rem;
		font-weight: 700;
		font-size:var(--font-size-small);
		letter-spacing: 0.05rem;
		text-transform: uppercase;
	}
	
	nav ul li span {
		text-align: center;
		position: relative;
		display:block;
		width:1.5rem;
	}
	
	@keyframes radar {
	  0% {
		transform: rotate(0deg);
	  }
	  100% {
		transform: rotate(360deg);
	  } 
	}
	
	nav ul li span.radar svg {
	  animation: radar 2s linear infinite;
	}
	
	nav ul li span svg {
		max-width:100%;
		height:auto;
	}
	
	nav ul li span svg path {
		fill:var(--color-white);
	}
	
	nav ul li:first-of-type a {
	}
	
	nav ul li:last-of-type a {
	}
	
	nav ul li a {
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
		justify-content: flex-end;
		white-space: nowrap;
		background-color:var(--color-black);
		display:flex;	
		margin:0;	
		padding:0.4rem 0.5rem 0.25rem 0.5rem;
		color:var(--color-white);
		text-decoration: none;
		transition:background-color 0.25s ease-in-out;
	}

	nav ul li a.active {
		color:var(--color-black);
		background-color:var(--color-light-grey);
	}
	
	nav ul li a.active:hover {
	}
	
	nav ul li a.active span svg path {
		fill:var(--color-black);
	}

.site-title {
	position: fixed;
	top:0.5rem;
	z-index:202;
	width:5rem;
	left:calc(50% - 2.5rem);
	font-size:var(--large-font-size);
	text-transform: uppercase;		
}

	.site-title:hover {
		cursor: pointer;
	}
	

	.site-title svg {
		max-width:100%;
		height:auto;
		background:none;
	}

.aircraft-label {
		fill: var(--color-white); /* Choose an appropriate color */
		font-size: var(--font-size-x-small); /* Adjust as needed */
		text-anchor: left; /* Center the text at the given (x, y) position */
	}

.logo-wrapper {
	width:5.6rem;
	height:5.6rem;
	position: relative;
}

	.logo-wrapper svg {
		position: absolute;
		top:0;
		left:0;		
	}
	
	.logo-wrapper svg.pink-circle {
		z-index:11;	
	}
	
	.logo-wrapper svg.north-south {
		z-index:12;	
		width:3rem;
		top:calc(50% - 1.5rem);
		left:calc(50% - 1.5rem);
		transform:rotate(0deg);
		transition: transform 0.5s ease-in-out;
	}
	
.site-title.south  svg.north-south {		
	transform:rotate(180deg);
}

.aircraft-detected {
	position: fixed;
	bottom:1rem;
	left:1rem;
}

.aircraft-overview {
	position: absolute;
	bottom: 0rem;
	left: 0rem;	
	font-family: var(--font-sans);
	width:100%;
	overflow: scroll;
	display:flex;
	justify-content: flex-start;
}
	
	@media only screen and (min-width: 45rem) {
		.aircraft-overview {
			justify-content: center;
		}
	}

	.aircraft-overview ul {
	  	display:flex;	
	  	grid-gap:0.5rem;
		list-style: none;
		margin:0;
		align-items: flex-end;
		padding:0.5rem;	
	}

	.aircraft-overview ul li {
		max-width:160px;
		min-width:160px;
		background-color:var(--color-black);
		color:var(--color-white);
		border-radius: 0.25rem;
		padding: 0.5rem;
		box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
		z-index: 10;
		transition:background 0.15s ease-in-out;
	}
	
	.aircraft-overview ul li h3 {
		margin:0;
		font-size:var(--font-size-small);
	}
	
	.aircraft-overview ul li p {
		margin:0;
		font-size:var(--font-size-small);
		font-family: var(--font-sans);
		color:var(--color-white);
	}
	
	.aircraft-overview ul li small {
		text-transform: uppercase;
		letter-spacing: 0.01rem;
		font-size:70%;
		margin:0;
		line-height: 1.1;
	}
	
	.aircraft-overview ul li.active {
		background-color:var(--color-map-highlight);
		color:var(--color-black);
	}
		.aircraft-overview ul li.active p {
			color:var(--color-black);
		}

	
	
	.aircraft-overview ul li:hover {
		cursor: pointer;
		background-color:var(--color-map-highlight);
	}
		.aircraft-overview ul li:hover h3,
		.aircraft-overview ul li:hover small,
		.aircraft-overview ul li:hover p {
			color:var(--color-black);
		}

.near-north-pole-info {
	position: fixed;
	left:0;
	display:flex;
	justify-content: center;
	bottom:4rem;
	z-index:11;
	width:100%;
}

.certificate-north {
	transform: rotate(-90deg);
	transform-origin: bottom left;
	padding:0.5rem 0.25m;
	position: relative;
	overflow: hidden;
	background-color:var(--color-white);
	margin-bottom:0.5rem;
	overflow: hidden;
}

	.certificate-north b {
		position: absolute;
		top:0;
		width:100%;
		padding:0.15rem 0.2rem;
		font-size:0.8rem;
		font-weight: normal;
		color:var(--color-black);
		background-color:var(--color-aircraft-north);
		left:1.3rem;
		transform-origin: top left;
		transform: rotate(90deg);
	}

.certificate-north ul {
	list-style: none;
	font-size: var(--font-size-small);
	margin:0;
	padding:0.5rem 0.5rem 0.5rem 1.75rem;
}

.ellipsis {
  display: inline-block;
  margin-left: 5px;
  animation: ellipsis 1.5s infinite;
}

@keyframes ellipsis {
  0% { content: ''; }
  33% { content: '.'; }
  66% { content: '..'; }
  100% { content: '...'; }
}

body.south .inline-message {
	bottom:5rem;
}

	body.south .inline-message span {
		color:var(--color-white);
	}
	
	
	
.inline-message {
	position:fixed;
	z-index:11;
	color:white;
	display:flex;
	text-align: center;
	justify-content: center;
	bottom:6rem;
	max-width:100%;
	width:100%;
	right:0;
}

.inline-message span {
	padding:0.5rem;
	text-align: center;
	line-height: 1.1;	
	font-family: var(--font-serif);
	font-size:var(--font-size-medium);
	font-weight:normal;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0.01;
	color:var(--color-white);	
}

	.inline-message span b {
		font-weight: 600;
		display:block;
		text-transform: none;
		letter-spacing: 0;
		font-family: var(--font-sans);
	}
	
@media only screen and (min-width: 45rem) {
	body.south .inline-message {
		bottom:40%;
	}
	body.south .inline-message span {
		color:var(--color-sea);	
	}
}

text.pole-text {
	text-transform: uppercase;
	font-family: var(--font-serif);
	font-size:var(--font-size-medium);
}

.overview {
	position: fixed;
	right:0.5rem;
	left:0.5rem;
	border-radius: 0.25rem;
	top:2.5rem;
	max-width:100%;
	z-index:11;	
	background:white;
	padding:0.5rem;
}

	@media only screen and (min-width: 45rem) {
		.overview {
			left:auto;
			width:20rem;
		}
	}

	.overview h2 {
		margin:0;
	}
	
	.overview ul {
		margin:0;
		list-style: none;
		padding:0;
	}
	
	.overview ul li {
		font-size:var(--font-size-small);
	}	
	
@keyframes fadeIn {
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
}
	
.label-animation {
  animation-name: fadeIn;
  animation-duration: 1s; /* Adjust as needed */
  animation-fill-mode: both; /* Keeps the label visible after the animation */
}

path.aircraft-marker {
	fill: var(--color-brand-primary);
}

@keyframes near-north-aircraft {
  0 {
	fill:var(--color-white);
  }
  50% {
	fill:var(--color-aircraft-north);
  }
  100% {
	  fill:var(--color-white);
	}
}

path.aircraft-marker.near-north-pole {
	fill:var(--color-white);
	animation-name: near-north-aircraft;
	animation-duration: 3s; /* Adjust as needed */
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}

path.aircraft-marker.active {
	fill:var(--color-red);
}

.aircraft-marker-enter {
  opacity: 0;
  transition: opacity 0.5s ease-out; /* Adjust time as necessary */
}

/* Final state for entered aircraft - fully opaque */
.aircraft-marker-enter-active {
  opacity: 1;
}

@keyframes fadeIn {
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
}

.airport-marker {
	animation: fadeIn 0.5s ease-in-out forwards;
}

	.airport-marker:hover {
		cursor: pointer;
	}

.airport-name {
	font-size:var(--font-size-x-small);
	fill:var(--color-white);
}

/* SHOW PAST TRAFFIC */

.button-holder {
	position: fixed;
	z-index:12;
	display:flex;
	right:0rem;
	top:0rem;
	width:100%;
}

	.button-wrapper {
		display:flex;		
		justify-content: space-between;
		width:100%;
	}
	
	.button-holder button {
		background:var(--color-super-black);
		border:none;
		margin:0;
		color:var(--color-white);
		padding:0.25rem 0.5rem;
		letter-spacing: 0.05rem;
		font-weight: bold;
		font-size:var(--font-size-x-small);
		text-transform: uppercase;
		transition:all 0.25s ease-in-out;
	}
	
	.button-holder button.active {
		background:var(--color-brand-primary);
	}
	
	.button-holder button:hover {
		cursor: pointer;
		background:var(--color-brand-primary);
	}	
	
		.button-holder button:first-of-type {
			padding-right: 0.5rem;
			border-bottom-right-radius: 0.25rem;
		}
		.button-holder button:last-of-type {
			padding-left:0.5rem;
			border-bottom-left-radius: 0.25rem;
	}

@media only screen and (min-width: 45rem) {
	/* .button-holder {
		top:-1px;
		right:1rem;
	}
	
	.button-wrapper {
		gap:1rem;
		justify-content: flex-end;
		flex-direction: row;	
	}
	
	.button-holder button {
		padding:0.5rem;
		border-bottom-right-radius: 0.25rem;
		border-bottom-left-radius: 0.25rem;
	} */	
}

.traffic-counter {
	position: fixed;
	bottom:0;
	z-index:12;
	background:white;
}


/* DATA */

.page.data {
	padding:0.5rem;
	z-index:11;
	min-height:100vh;
	background:white;	
	padding-top:var(--header-margin);
}

.page.data h1 {
	margin-bottom:1.5rem;
	text-align: center;
	font-weight: normal;
	letter-spacing: 0.1rem;
	font-family: var(--font-serif);
	text-transform: uppercase;
	font-size:var(--font-size-xx-large);	
}

.page .page-header {
	max-width:500px;
	margin:0 auto;
	text-align: center;	
	margin-bottom:1.5rem;
}

.page .page-header p {
	text-align: center;				
}

.page.data table {
	border-spacing: 0;
	width:100%;
	border-collapse: collapse;
	padding-bottom:10%;

}

.page.data table thead {
	text-align: left;
	white-space: nowrap;
}	
	
	.page.data table thead th {
		text-transform: uppercase;
		padding-right: 0.5rem;
		font-size:var(--font-size-medium);
	}

	
	.page.data table tr {
		font-size:var(--font-size-small);
	}
	
	.page.data table td.counter b {
		background-color:var(--color-brand-primary);
		width:1.5rem;
		display:block;
		height:1.5rem;
		color:var(--color-white);
		text-align: center;
		line-height: 1.5rem;
		font-weight: bold;
		font-size:var(--font-size-small);
		border-radius: 2rem;	
	}

	.page.data table th { 
		
	}

	.page.data table td {
		white-space: nowrap;
		padding:0.75rem 0;
		border-bottom:1px solid var(--color-brand-primary);
		padding-right:1rem;
	}
	
	.page.data table td:last-of-type {
		padding-right: 0;
	}
	
	@media screen and (max-width: 600px) {
	  .page.data table {
		border: 0;
		table-layout: fixed;
	  }
	
	  .page.data table caption {
		font-size: 1.3em;
	  }
	  
	  .page.data table thead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	  }
	  
	 .page.data  table tr {
		border-bottom: 3px solid var(--color-brand-primary);
		display: block;
		margin-bottom: .625em;
	  }
	  
	 .page.data  table td {
		display: block;
		padding-right: 0;
		text-align: right;
	  }
	  
	  .page.data table td::before {
		text-align: right;
		content: attr(data-label);
		float: left;
		font-weight: bold;
		text-transform: uppercase;
	  }
	  
	  .page.datatable td:last-child {
		border-bottom: 0;
	  }
	}

/* ABOUT */

body {
	transition:background-color 0.25s ease-in-out;
	background-color:var(--color-map-sea);

}

article.story {
}

	article.story header.story-header {
		position: relative;
		display:flex;
		justify-content: center;
		align-items: center;
		height:100vh;
		height:100dvh;
		margin-bottom:5vh;
	}
	
		@media only screen and (min-width: 45rem) {
			article.story header.story-header {
				
			}
		}
	
	article.story header.story-header .route-holder {
		display:flex;
		height:100vh;
		align-items: center;
		overflow: hidden;
	}
	
	article.story .svg-holder {
		position: relative;
		width:300px;
		height:300px;
	}
	
	@media only screen and (min-width: 45rem) {
		article.story .svg-holder {
			position: relative;
			width:400px;
			height:400px;
		}
	}

	article.story header.story-header svg {
		position: absolute;
		z-index:10;
		top:0;
		bottom:0;
		left:0;
		right:0;
		width:100%;
		height:100%;
	}

	article.story .headline {
		position: absolute;
		bottom:6rem;
		left:0;
		width:100%;
		z-index:10;
		text-align: center;
	}
	
	@media only screen and (min-width: 45rem) {
		bottom:2rem;
	}

	article.story .headline h1 {
		letter-spacing: 0.2rem;
		font-family: var(--font-serif);
		color:var(--color-white);
		font-weight: normal;
		font-size: var(--font-size-xxx-large);
		text-transform: uppercase;
	}
	
		article.story .headline h2 {
			color:var(--color-white);
			margin:0;
			font-size: var(--font-size-medium);
			font-family: var(--font-sans);	
			font-weight: 500;
		}
		
	@media only screen and (min-width: 45rem) {
		
	}	
	
article.story .hero-video {
	width:100%;
	overflow: hidden;
	top:0;	
	right:0;
	left:0;
	padding-bottom:100vh;
	position: absolute;
}
	article.story .hero-video video {
		position: absolute;
		top:0;
		width:100%;
		height:100%;
		object-fit: cover;
		left:0;
	}
	
		[data-section-type=headline].article-segment,
		[data-section-type=text].article-segment {
			opacity: 0;
			transition: all 0.5s ease-in-out;			
		}
	
		.article-segment.in-view {
			opacity: 1;
		}
	
		.note,
		.paragraphs {
			padding:0.5rem;
		}
			
		@media only screen and (min-width: 45rem) {
			.paragraphs {
				margin:0 auto;
				grid-column: 3 / 11;
			}
			
			.paragraphs,
			.note {				
			}
			
			[data-section-type=headline] .segment-inner,
			[data-section-type=text] .segment-inner {
				display:grid;
				max-width:55rem;
				margin:0 auto;
				grid-template-columns: repeat(12, 1fr);
			} 
		}
		
		.article-segment .note {			
			grid-column: 11 / 13;
		}
		
		.paragraphs h3 {
			margin-top:0;
			font-size:var(--font-size-x-large);
			font-weight: normal;
			color:var(--color-white);
			line-height: 1;
			font-family: var(--font-serif);
			margin-bottom:0.5rem;
		}
	
		.paragraphs p {
			margin-top:0;
			margin-bottom:1rem;
			line-height: 1.3;
			font-size:var(--font-size-medium-bigger);
			color:var(--color-white);				
		}
		
		.paragraphs p span {
			color:var(--color-brand-primary);
		}
									
		.article-segment .note small {
			line-height: 1.25;
			padding:0.5rem;
			display:inline-block;
			padding:0.5rem;
			position: relative;
			color:var(--color-brand-primary);
			border-top:2px solid var(--color-brand-primary);
			margin-top:0.5rem;
			font-size:var(--font-size-small);
			border-left:2px solid var(--color-brand-primary);
		}
				
		.article-segment:last-of-type {
			padding-bottom:5rem;
		}
	
	article.story .article-segment.film {
		display:block;
	}	
	
	article.story .film-wrapper {
		position: relative;
		margin-bottom:1.5rem;
		margin-top:1.5rem;
		padding-bottom:56.5%;
	}
	
	article.story .film-wrapper iframe {
		position: absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		right:0;
		bottom:0;		
	}
	
	article.story .single-image {
		margin-top:5vh;
		margin-bottom:5vh;
	}
	
	article.story .single-image .image-holder {
		padding-bottom:1rem;	
		background-color:var(--color-white);
	}
	
	
.image-show {
	margin-top:2rem;
	margin-bottom:2rem;
	display:flex;
	width:100%;
	gap:2rem;
	padding-bottom:2rem;
	padding-top:1rem;
	overflow: scroll;
	background-color:var(--color-white);
}

	@media only screen and (min-width: 600xp) {
		.image-show::-webkit-scrollbar {
	  	display: none;
		}
	}
	
	.image-show .image-column {
		flex: 0 0 auto;
	}
	
	.image-show .image-column img {
		height: var(--element-height);
		width: auto;
		max-width: 100%;
	}	

.image-column {
}

.page figcaption {
	text-align: center;
	color:var(--color-black);
	font-size:var(--font-size-x-small);
}

.newmap svg {
	height:100vh;
}

.temp {
	position: absolute;
	bottom:1rem;
	left:1rem;
	z-index:200;
}

.globe-container {
  /* Adjust these styles as needed to position the globe */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Align to center, change this to move the globe */
  align-items: flex-end; /* Align to middle, change this to move the globe */
}

.new-about .page-content {
	max-width:50%;
	margin:0 auto;
}

	.new-about .page-content p {
		color:var(--color-white);	
		font-size:1.25rem;
	}
	
	.globe-col {
		position: fixed;
		top:0;
		height:100vh;
		display:flex;
		right:0;
		width:20%;
	}

.globe-holder {
	position: relative;
	width:100%;	
}

	.globe-holder svg {
		position: absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		width:100%;	
		height:100%;
	}

.certificates {
	
}
p.no-certificates {
	text-align: center;
	font-size:var(--font-size-large);
}

.certificate {
	display:flex;
	padding:0.5rem;
	transition:background-color 0.25s ease-in-out;
	flex-direction: column;
}

	.certificate:nth-of-type(odd) {
			background-color:var(--color-light-grey);
	}
		
	.certificate.active {
		background-color:var(--color-orange);
	}	
	
	.certificate.active:hover {
		background-color:var(--color-orange);
	}

	.certificate:hover {
		cursor:pointer;
		background-color:var(--color-mid-grey);
	}

	@media only screen and (min-width: 45rem) {
		.certificate {
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
		}
	}

	.certificate button {
		background-color:var(--color-brand-primary);
		border:none;
		transition:all 0.15s ease-in-out;
		color:var(--color-white);
		padding:0.25rem 0.5rem;
		border-radius: 0.25rem;
	}
	
		.certificate form {
			display:grid;
			grid-template-columns: 1fr 1fr;
			grid-gap:0.5rem;
		}
		
		.certificate form input {
			padding:0.25rem;
			width:calc(100% - 0.5rem);
			border-radius: 0.25rm;
		}
	
		.certificate button:hover {
			cursor: pointer;
			background-color:var(--color-black);
		}

	.certificate.active button.create {
		display:none;
	}

	.certificate .flight-info {
		font-size:var(--font-size-medium);
		margin-bottom:0.5rem;
		display:flex;
		align-items: center;
	}
	
	@media only screen and (min-width: 45rem) {
		.certificate .flight-info {
			margin-bottom:0;	
		}
	}

	.certificate .flight-info b {
    	margin-right: 0.5rem;
		white-space: nowrap;
		display:inline-block;
		font-size:var(--font-size-small);
	}
	
	.certificate .flight-info small {
		white-space: nowrap;
		display:inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	.certificate-map-wrapper {
		//display:none;
	}
	
	.map-image {
		display:none;
	}
	
	.certificate-map {
		display:none;
		background-color:var(--color-white);
		border:1px solid var(--color-black);
	}
	
.certificate-navigation {
	padding: 0.5rem 0;
	display:flex;
	width:100%;
	overflow: hidden;
}

	@media only screen and (min-width: 45rem) {
		.certificate-navigation ul {
			justify-content: center;
		}
	}

	.certificate-navigation ul {
		list-style: none;
		display:flex;
		margin:0;
		padding:0 0 1rem 0;
		gap:1rem;	
		width:100%;
		overflow: scroll;
	}
	
	.certificate-navigation ul li {
		font-family: var(--font-sans);
		font-weight: 700;
		line-height: 1.2;
		white-space: nowrap;
		text-transform: uppercase;
	}
	
	.certificate-navigation ul li a {
		text-decoration: none;
		display:block;
		color:var(--color-black);	
		padding:0.25rem 0.5rem 0.25rem 0.5rem;
		background-color:var(--color-light-grey);
		transition: all 0.25s ease-in-out;
	}
	
	.certificate-navigation ul li a:hover {
		background-color:var(--color-mid-grey);
	}
	
	.certificate-navigation ul li a.active {
		background-color:var(--color-brand-primary);
		color:var(--color-white);
	}